import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n.use(Backend)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'sv',
        // fallbackLng: ['sv', 'en'],

        fallbackLng: ['sv'],
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false
        },
        backend: {
            queryStringParams: {v: '2.5.1'} // version of your app this will bypass cache when you change version
        }
    });

export default i18n;
